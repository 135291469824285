import { graphql, PageProps } from "gatsby";
import React from "react";

import { Query } from "@graphql-types";
import { ErrorMsg } from "@shared";
import ContactTemplate from "../templates/contactTemplate";

interface Props extends PageProps {
  data: Query;
}

const ContactUs = ({ data }: Props) => {
  if (data.sanityFeedbackPage == null)
    return <ErrorMsg data={data.sanityFeedbackPage} msg="Error fetching data for page" />;

  const {
    sanityFeedbackPage: { title, blockContent },
  } = data;

  return (
    <div>
      <ContactTemplate data={data} title={title} hero={blockContent} formType="contact" />
    </div>
  );
};

export default ContactUs;

export const query = graphql`
  query FeedbackPageQuery {
    sanityHeader {
      ...sanityHeader
    }
    sanityFeedbackPage {
      title
      seo {
        ...sanitySeo
      }
      pageColour {
        ...sanityColorPicker
      }
      blockContent {
        _rawBlockContent(resolveReferences: { maxDepth: 5 })
      }
      ctaTitle
      contactCtas {
        ...sanityContactCta
      }
      enquiryContacts {
        ...sanityContactCta
      }
      ctas {
        ...sanityLink
      }
      form {
        ...sanityForm
      }
    }
  }
`;
